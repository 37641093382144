<template>
  <section class="home-block py-0 text-white d-none d-md-block">
    <div class="background">
      <div
        ref="background"
        class="video-background"
        :style="{
          backgroundImage: 'url(' + require('@i/video/business.jpg') + ')',
        }"
      >
        <video
          class="lazy"
          autoplay
          muted
          loop
          playsinline
          preload="none"
          :data-src="require('@i/video/business.mp4')"
          src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
        />
      </div>
    </div>
    <div ref="particle" class="particle" />
    <div class="content">
      <div class="container">
        <div class="flex">
          <div ref="title" class="h2 title">
            До&nbsp;всего&nbsp;&mdash; пешком
          </div>
          <div ref="text" class="text">
            <p>
              Развлечения и&nbsp;спорт, природа, прогулки, культура
              и&nbsp;шопинг&nbsp;&mdash; когда всё рядом, вы&nbsp;успеваете
              больше и&nbsp;можете проводить VERY много времени вместе.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { lazyVideo, is_touch } from "@/helpers.js";
import { gsap } from "gsap";
export default {
  mounted() {
    lazyVideo(this.$el);

    if (!is_touch()) {
      gsap.fromTo(
        this.$refs.background,
        { y: "-25%" },
        {
          y: "25%",
          scrollTrigger: {
            trigger: this.$el,
            scrub: true,
          },
          ease: "none",
        }
      );

      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            start: "top 60%",
            end: "center center",
            scrub: 1.5,
          },
        })
        .from(
          gsap.utils.toArray([this.$refs.title, this.$refs.text]),
          {
            y: 150,
            stagger: { each: 0.1 },
          },
          0
        )
        .from(
          this.$refs.particle,
          {
            autoAlpha: 0,
          },
          0
        );
    }
  },
};
</script>

<style scoped>
.video-background {
  top: -25%;
  bottom: -25%;
  height: auto;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(63 68 64 / 0.3);
}

.particle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 957px;
  height: 818px;
  margin-top: -409px;
  margin-left: -210px;
  background: url(~@i/bg-family.svg) 0 0 no-repeat;
}

.content {
  position: relative;
}

.flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 720px;
  padding: 80px 0;
}

.text {
  max-width: 585px;
  font-size: 20px;
}
</style>
