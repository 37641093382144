<template>
  <Head>
    <title>Преимущества {{ $titleEnd }}</title>
    <meta
      name="description"
      content="VERY полностью меняет восприятие жизни в Москве. Город здесь покоряется природе, становясь её необходимым и комфортным приложением. Ознакомиться со списком преимуществ жилого комплекса VERY (Вери) на ул Ботаническая 29 в Москве от ГК ОСНОВА."
    />
    <meta property="og:title" :content="'Преимущества ' + $titleEnd" />
    <meta
      property="og:description"
      content="VERY полностью меняет восприятие жизни в Москве. Город здесь покоряется природе, становясь её необходимым и комфортным приложением. Ознакомиться со списком преимуществ жилого комплекса VERY (Вери) на ул Ботаническая 29 в Москве от ГК ОСНОВА."
    />
  </Head>
  <main class="main mb-0">
    <Block1 />
    <Video />
    <Block2 />
    <Secondary class="pt-0" />
    <HomeChoose />
  </main>
</template>

<script>
import Block1 from "@/components/adv/index/Block1.vue";
import Video from "@/components/adv/index/Video.vue";
import Block2 from "@/components/adv/index/Block2.vue";
import Secondary from "@/components/adv/index/Secondary.vue";
import HomeChoose from "@/components/home/Choose.vue";
import { Head } from "@vueuse/head";
export default {
  components: {
    Head,
    Block1,
    Video,
    Block2,
    Secondary,
    HomeChoose,
  },
};
</script>

<style scoped></style>
