<template>
  <section class="home-block">
    <div class="background d-none d-md-block">
      <div
        class="video-background"
        :style="{
          backgroundImage:
            'url(' + require('@i/video/home-lifestyle.jpg') + ')',
        }"
      />
    </div>
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-xl-5 col-md-6">
            <AdvItem v-if="itemByLink('/parking/')" class="item-parking" :item="itemByLink('/parking/')" />
            <AdvItem v-if="itemByLink('/smart/')" class="item-smart" :item="itemByLink('/smart/')" />
          </div>
          <div class="col-xl-5 offset-xl-1 col-md-6">
            <AdvItem v-if="itemByLink('/kids/')" class="item-kids" :item="itemByLink('/kids/')" />
            <div ref="booklet" class="booklet">
              <div class="h3 booklet__title">Very всегда рядом</div>
              <div class="booklet__text">
                <p>
                  Вся информация о&nbsp;квартале&nbsp;&mdash; в&nbsp;красочном
                  буклете. Знакомьтесь с&nbsp;преимуществами и&nbsp;подробно
                  изучайте планировки.
                </p>
              </div>
              <div class="booklet__btn">
                <button
                  class="btn btn-default btn-small"
                  data-popup-with-chats
                  data-popup-with-chats-title="Получить буклет"
                >
                  Получить буклет
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { lazyVideo, is_touch } from "@/helpers.js";
import AdvItem from "@/components/adv/index/Item.vue";
import { gsap } from "gsap";
export default {
  components: {
    AdvItem,
  },
  inject: ['mainStore'],
  computed: {
    items() {
      return this.mainStore.state.data?.benefit_project.slides ?? [];
    },
    preparedItems() {
      return this.items.map((item) => {
        return {
          img: item.preview.source,
          title: item.name,
          text: item.description,
          link: item.url
        };
      });
    }
  },
  mounted() {
    lazyVideo(this.$el);
    if (!is_touch()) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$refs.booklet,
            start: "top bottom",
            end: "center center",
            scrub: 1.5,
          },
        })
        .from(
          this.$refs.booklet.children,
          {
            y: 50,
            stagger: 0.1,
          },
          0
        );
    }
  },
  methods: {
    itemByLink(link) {
      return this.preparedItems.find((item) => item.link === link);
    },
  },
};
</script>

<style scoped>
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 55.555555%;
}

.background .video-background::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(180deg, rgb(255 255 255 / 0) 0%, #fff 100%);
}

.background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255 255 255 / 0.5);
}

.content {
  position: relative;
}

.subtitle {
  margin-top: 5px;
  margin-bottom: 32px;
}

.text {
  margin-bottom: 120px;
  font-size: 20px;
}

.booklet {
  margin-top: 252px;
}

.booklet__title {
  margin-bottom: 22px;
  line-height: 0.9;
}

.booklet__text {
  max-width: 390px;
}

.booklet__btn {
  margin-top: 33px;
}
@media (max-width: 1279px) {
  .booklet {
    margin-top: 232px;
  }
}
@media (max-width: 991px) {
  .booklet {
    margin-top: 159px;
  }
}
@media (max-width: 767px) {
  .home-block {
    padding-top: 0 !important;
  }

  .booklet {
    margin-top: 65px;
    text-align: center;
  }

  .booklet__title {
    line-height: 1;
  }

  .booklet__text {
    margin-right: auto;
    margin-left: auto;
  }
}
</style>
