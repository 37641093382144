<template>
  <section class="home-block">
    <div class="container">
      <div ref="content" class="content">
        <div ref="title" class="h2 title">
          Функциональность, инновации, комфорт
        </div>
        <div ref="text" class="text">
          <p>
            Дизайн общественных пространств и&nbsp;работа сервисов дома
            организованы так, чтобы чувство комфорта оставалось с&nbsp;вами
            постоянно.
          </p>
        </div>
      </div>
      <div ref="list" class="list">
        <div class="row">
          <div v-for="item in preparedItems" class="col-md-6">
            <SlidesItem :item="item" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SlidesItem from "@/components/SlidesItem.vue";
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
export default {
  components: {
    SlidesItem,
  },
  inject: ['mainStore'],
  computed: {
    items() {
      return this.mainStore.state.data?.benefit_project.slides ?? [];
    },
    preparedItems() {
      return this.items.filter(({ url }) => !url).map((item) => {
        return {
          img: item.preview.source,
          title: item.name,
          text: item.description,
          link: item.url
        };
      });
    }
  },
  mounted() {
    if (!is_touch()) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            start: "top bottom",
            end: "40% center",
            scrub: 1.5,
          },
        })
        .from(
          gsap.utils.toArray([this.$refs.title, this.$refs.text]),
          {
            y: 150,
            stagger: { each: 0.1 },
          },
          0
        );

      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$refs.list,
            start: "top 90%",
            end: "25% center",
            scrub: 1.5,
          },
        })
        .from(
          this.$refs.list.querySelectorAll(".item"),
          {
            y: 150,
            stagger: { each: 0.1 },
          },
          0
        )
        .from(
          this.$refs.list.querySelectorAll(".item__content"),
          {
            y: 100,
            stagger: { each: 0.1 },
            ease: "power2.out",
          },
          0
        )
        .from(
          this.$refs.list.querySelectorAll("img.img-to-animate"),
          {
            scale: 1.5,
            stagger: { each: 0.1 },
          },
          0
        );
    }
  },
};
</script>

<style scoped>
.title {
  max-width: 800px;
  margin: 0 auto 40px;
  text-align: center;
}

.text {
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
}

.list {
  margin-top: 60px;
}

.item {
  max-width: 100%;
}
@media (max-width: 991px) {
  .title {
    font-size: 46px;
  }
}
@media (max-width: 767px) {
  .title {
    margin-bottom: 27px;
    font-size: 42px;
  }

  .list {
    margin-top: 37px;
  }

  .list > .row > div + div {
    margin-top: 24px;
  }
}
@media (max-width: 575px) {
  .title {
    font-size: 36px;
  }
}
</style>
