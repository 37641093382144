<template>
  <section class="home-block pt-0">
    <div class="background d-none d-md-block">
      <div
        class="video-background"
        :style="{
          backgroundImage:
            'url(' + require('@i/video/home-lifestyle.jpg') + ')',
        }"
      />
    </div>
    <div class="content">
      <div class="container">
        <div class="row">
          <div ref="content" class="col-lg-7">
            <Breadcrumbs
              :links="[
                { name: 'Home', text: 'Главная' },
                { text: 'Преимущества' },
              ]"
            />
            <h1 class="h5 subtitle">Преимущества ЖК&nbsp;Very</h1>
            <div class="title h1">
              Территория <span class="text-masked">уникальных</span> впечатлений
            </div>
            <div class="text">
              <p>
                VERY полностью меняет восприятие жизни в&nbsp;Москве. Город
                здесь покоряется природе, становясь её&nbsp;необходимым
                и&nbsp;комфортным приложением.
              </p>
            </div>
          </div>
        </div>
        <div ref="row" class="row">
          <div class="col-xl-5 offset-xl-2 col-md-6 order-md-1">
            <AdvItem
              v-if="itemByLink('/entertainment/')"
              class="item-entertainment"
              :item="itemByLink('/entertainment/')"
              :img="require('@i/html/entertainment/entertainment-2.jpg')"
            />
          </div>
          <div class="col-xl-5 col-md-6 order-md-0">
            <AdvItem v-if="itemByLink('/sport/')" class="item-sport" :item="itemByLink('/sport/')" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumbs from "@/components/UI/Breadcrumbs.vue";
import { lazyVideo, is_touch } from "@/helpers.js";
import AdvItem from "@/components/adv/index/Item.vue";
import { gsap } from "gsap";
export default {
  components: {
    AdvItem,
    Breadcrumbs,
  },
  inject: ['mainStore'],
  computed: {
    items() {
      return this.mainStore.state.data?.benefit_project.slides ?? [];
    },
    preparedItems() {
      return this.items.map((item) => {
        return {
          img: item.preview.source,
          title: item.name,
          text: item.description,
          link: item.url
        };
      });
    }
  },
  mounted() {
    lazyVideo(this.$el);
    if (!is_touch()) {
      gsap.from(
        gsap.utils.toArray([this.$refs.content.children, this.$refs.row]),
        {
          autoAlpha: 0,
          y: 30,
          stagger: 0.1,
        }
      );
    }
  },
  methods: {
    itemByLink(link) {
      return this.preparedItems.find((item) => item.link === link);
    },
  },
};
</script>

<style scoped>
.background {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 55.555555%;
}

.background .video-background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(0deg, rgb(255 255 255 / 0) 0%, #fff 100%);
}

.background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255 255 255 / 0.5);
}

.content {
  position: relative;
}

.subtitle {
  margin-bottom: 32px;
}

.text {
  margin-bottom: 120px;
  font-size: 20px;
}
@media (max-width: 991px) {
  .subtitle {
    text-align: center;
  }

  .title {
    text-align: center;
  }

  .text {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .title {
    font-size: 88px;
  }

  .text {
    margin-bottom: 40px;
    font-size: 16px;
  }

  .home-block {
    padding-bottom: 0 !important;
  }
}
@media (max-width: 575px) {
  .title {
    font-size: 64px;
  }
}
@media (max-width: 374px) {
  .title {
    font-size: 56px;
  }
}

.breadcrumbs {
  margin-bottom: 40px;
}
@media (max-width: 991px) {
  .breadcrumbs {
    width: 100%;
    justify-content: center;
  }
}
</style>
